.App {
  text-align: center;
}

.App-logo {
  height: 30vmin;
  /* height: 300px; */
  /* width: 220px; */
  pointer-events: none;
  border-radius: 50%;
  border-color: white;
  border-style: solid;
  border-width: 2px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Socials {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
  justify-content: center;
}

.Socials > i {
  cursor: pointer;
}
.Socials > a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

.Name {
  display: flex;
  flex-direction: row;
  font-size: 6vmin;
  margin: 0;
  padding-bottom: 20px;
}

.App-link {
  color: #61dafb;
}

@media screen and (max-width: 800px) {
  .editor {
    display: none !important;
  }
  .Name {
    padding-top: 25px;
    padding-bottom: 25px;
    font-size: 10vmin;
  }
  .Socials {
    margin: 50px;
  }
  /* .App-logo { */
  /*   size: 15vmin; */
  /* } */
}
